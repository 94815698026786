/* *{
  font-family: 'Quicksand',sans-serif;
} */

.graphik-font{
  font-family: 'Graphik' !important;
}

.quicksand-font{
  font-family: 'Quicksand',sans-serif;
}

.error_outline:focus{
  border: 2px solid #DD0F0F !important;
}

.error_outline{
  border: 2px solid #DD0F0F !important;
}
.no_outline{
  border: 2px solid black;
}

.profile{
  z-index: 100 !important;
}

#dynamic_form_complex{
  width: 600px !important;
}


.no-profile{
  z-index: 10 !important;
}

.spinning_indicator{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #ffffff;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidenav{
  height: calc(100vh - 80px);
}

.ant-select-item-option-content{
  display: flex !important;
  flex-direction: row !important;
}

.ant-select-selection-item{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center;
}

#dynamic_form_complex{
  width: 50vw !important;
  max-width: 50vw !important;
}

.refresh_btn svg  {
  transition: transform 3s ease-in-out;
}
.rotated {
  transform: rotate(1620deg);
}

.disabled_btn{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.ant-form-item-explain.ant-form-item-explain-connected{
  display: none !important;
}

.toast-message{
  height: 20px !important;
}



